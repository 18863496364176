import {
  handleError, camelToSentence, isEmptyObject, highlightString,
} from '@/utils/utils';
import { mapMutations } from 'vuex';
import * as types from '@/store/mutation-types';
import RouteGeneratorMixin from './RouteGeneratorMixin';

export default {
  mixins: [RouteGeneratorMixin],
  data() {
    return {
      item: {},
      items: [],
      meta: {},
      errors: {},
      options: {
        page: 1,
      },
      loading: false,
      itemIndex: -1,
      footerProps: {
        'disable-items-per-page': true,
        'show-first-last-page': true,
      },
    };
  },

  computed: {
    routeQuery() {
      return this.$route.query;
    },
  },

  watch: {
    'options.page': {
      handler(newPage, oldPage) {
        if ((newPage !== oldPage && oldPage)) {
          // push for page 2 and so on
          this.$router.push(this.routeGenerator({ page: newPage })).catch((err) => { });
        }
      },
    },

    routeQuery: {
      deep: true,
      immediate: true,
      handler(queryObj) {
        if (this.filter && !isEmptyObject(queryObj)) {
          // if there is query object
          // then get data from route query, then assign to filter obj
          Object.keys(this.filter).forEach((f) => {
            this.filter[f] = Number(queryObj[f]) || queryObj[f] || null;
          });
          this.options.page = Number(queryObj.page) || 1;
        }

        if (isEmptyObject(queryObj)) {
          // when click in navigation drawer, reset the query
          this.$router.replace(this.routeGenerator({ reset: true })).catch((err) => { });
        } else {
          this.getItem();
        }
      },
    },
  },

  methods: {
    ...mapMutations([types.ERROR]),

    // can be called by this
    handleError,
    camelToSentence,
    highlightString,

    async getItem(page) {
      this.loading = true;

      try {
        const result = await this.getData(page);
        this.items = result.data.data;
        // set meta
        if (result.data.meta) {
          this.meta = result.data.meta;
        } else {
          this.meta = {
            total: this.items.length,
          };
        }
        this.options.itemsPerPage = this.meta.per_page ?? this.meta.total;
      } catch (error) {
        console.log(error);
        this.$toast.error('Failed Get Data');
      }

      this.loading = false;
    },

    openForm(item = null) {
      if (item) {
        this.itemIndex = this.items.indexOf(item);
        this.item = { ...item };
      } else {
        this.itemIndex = -1;
        this.item = {};
      }
      this.$refs.formModal.dialog = true;
    },

    async deleteItem(item) {
      if (await this.$root.$confirm('Delete', `Are you sure want to delete ${item.name} ?`, { color: 'error' })) {
        this.loading = true;

        try {
          const res = await this.deleteData(item.id);
          if (res.status === 204) {
            const index = this.items.indexOf(item);
            this.items.splice(index, 1);
            // decrement total items
            this.meta.total -= 1;
            this.$emit('reload-data');
            this.$toast.success('Success Delete Data');
          }
        } catch (error) {
          handleError(error);
          this.$toast.error('Failed Delete Data');
        }

        this.loading = false;
      }
    },
  },
};
