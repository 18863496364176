<template>
  <v-row>
    <v-col>
      <v-card>
        <v-toolbar flat>
          <v-icon>mdi-cash</v-icon>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-toolbar-title class="d-none d-sm-flex align-center">
            <span>{{ camelToSentence($options.name) }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            color="primary"
            dark
            @click="openForm(null)"
          >
            Add
          </v-btn>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="meta.total"
          :loading="loading"
          disable-sort
          mobile-breakpoint="0"
          :footer-props="footerProps"
        >
          <!-- actions -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="mr-2"
                  v-on="on"
                  @click="openForm(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  color="error"
                  class="mr-2"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>

      <PlanForm
        ref="formModal"
        :item="item"
        @reload-data="handlingReload"
      />
    </v-col>
  </v-row>
</template>

<script>
import DatalistMixin from '../../mixins/DatalistMixin';
import PlanForm from './PlanForm.vue';


export default {
  name: 'PlanList',
  components: { PlanForm },

  mixins: [DatalistMixin],
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          width: '200px',
        },
        {
          text: 'Max Users',
          value: 'max_users',
        },
        {
          text: 'Max Devices',
          value: 'max_devices',
        },
        {
          text: 'Max Uplinks',
          value: 'max_uplinks',
        },
        {
          text: 'Max Schedules',
          value: 'max_schedules',
        },
        {
          text: 'Max Dashboards',
          value: 'max_dashboards',
        },
        {
          text: 'Max Widgets',
          value: 'max_widgets',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '120px',
        },
      ],
    };
  },

  mounted() {
    // event from delete data
    this.$on('reload-data', () => {
      this.handlingReload();
    });
  },

  methods: {
    getData(page = 1) {
      return this.$api.getPlans({
        page,
      });
    },

    deleteData(planId) {
      return this.$api.deletePlan(planId);
    },

    handlingReload(event = null) {
      // update vuex store - no paginate
      this.$store.dispatch('getPlans', {
        forceGet: true,
      });

      // update local store - with paginate
      this.getItem();
    },
  },
};
</script>
